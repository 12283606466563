import { jarallax } from "jarallax";

export default function Parallax() {
    const parallax = document.querySelectorAll(".jarallax");
    if (parallax.length > 0) {
        parallax.forEach(element => {
            element.firstElementChild.classList.add('jarallax-img');
        });
    
        // Init jarallax
        jarallax(parallax, {
            speed: 0.2
        });
    }
}
