export default function Wish(elements, Notifications) {
    elements.forEach(element => {
        element.addEventListener('click', () => {
            if (element.classList.contains('product-wish')) {
                elements.forEach(element => {
                    element.classList.remove('product-wish');
                });
                Notifications.Toast.fire({
                    title: 'Ce produit a été retiré de votre liste de souhaits',
                    icon: 'info'
                });
            } else {
                elements.forEach(element => {
                    element.classList.add('product-wish');
                });
                Notifications.Toast.fire({
                    title: 'Ce produit a été ajouté de votre liste de souhaits',
                    icon: 'info'
                });
            }
        })
    });
}