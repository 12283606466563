
// import Notifications from "./globalJs";
import Share from "./share";
import * as preline from "preline";
import { Loader } from "./loader";
import { BackTop } from "./backTop";
// import { forms } from "./forms";
// import BarreTop from "./menuBarre";
import Parallax from "./parallax";
import { headingUnderline } from "./headingUnderline";
// import Number from "./number";
// import Cart from "./cart";
// import Address from "./address";
// import Payement from "./payement";
import Search from "./search";
// import Account from "./account";
// import Reset from "./reset";
import Wish from "./wish";
import Instafeed from "./instafeed";

if (document.querySelector('#loader')) {
    new Loader('#loader');
}

if (document.querySelector('.share__list')){
    new Share(document.querySelector('.share__list'), Notifications);
}

if (document.querySelector('#topButton')) {
    const backTop = new BackTop(document.querySelector('#topButton'), {
        type: "slide--bottom"  // slide--bottom, slide--right, zoom, fade
    })
}

if (document.querySelector('.form')) {
    let initFormContact = new forms('form')
}

if (document.querySelector('.jarallax')) {
    new Parallax()
}

if (document.querySelector('.heading--underline')) {
    const elements = document.querySelectorAll('.heading--underline');
    elements.forEach(element => {
        new headingUnderline(element);
    });
}

// if (document.querySelector('.field__quantity')) {
//     new Number(document.querySelector('.field__quantity'));
// }

if (document.querySelector('.open__search')) {
    new Search(document.querySelector('.open__search'));
}

// if (document.querySelector('.menu__barre')){
//     new BarreTop();
// }

if (document.querySelector('.addWish')){
    new Wish(document.querySelectorAll('.addWish'), Notifications);
}

// if (window.location.pathname === '/compte') {
//     new Account();
// }

// if (window.location.pathname === '/forgot' | window.location.pathname === '/resetpsw') {
//     new Reset();
// }

// if (window.location.pathname === '/panier') {
//     new Cart();
// }

// if (window.location.pathname === '/commande') {
//     new Address();
// }

// if (window.location.pathname === '/panier/paiement' ) {
//     new Payement();
// }

// import('./instagramFeed').then(async (module) => {

//     try {
//         let initInstagram = await new module.InstaFeed(
//             `instagram-feed`,
//             {
//                 id: '17841449729580353',
//                 accessToken: 'EAAL12VhOgjgBO1PkFOAAOQtzxM6uZAnRGHRZC14ZBZCty9zOoNiGN49ZAZBkYojA7zpgwAcohQZAJn4Oae6xVZC3QwQ8UWYhNwfygCLky2fOCSZCwceJYKt3NV7ayVnKCxUGIrZBAAqsPMDkUqjsX7sYNipoxWz4ZB6Kt1uJV28zXZCQzzfFaGDWFZCmYLmf53o2g3FbmfZAUZCLACsU5xhZBbNfYy3TvCsafOWbuQ6V1ixNZBWUZBuC5N',
//                 numPhotos: 8
//             }
//         )
//     } catch (Error) {
//         console.log(Error)
//     }
// });

document.querySelectorAll('.instafeed .instafeed__grid').forEach(element => {
    new Instafeed(element,{
        api: 'https://feeds.behold.so/kLh0WO1IVKFIDSuiywBZ',
    })
});