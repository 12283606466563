export function headingUnderline(heading) {
    const line = heading.querySelector('.line__wrapper');

    const windowHeight = window.innerHeight;
    const offset = 0.3;
    let actionTriggered = false;

    function checkVisibility() {
        const elementRect = heading.getBoundingClientRect();
        const elementTop = elementRect.top;
        const elementBottom = elementRect.bottom;

        if (elementTop < windowHeight * (0.5 + offset) && elementBottom > windowHeight * (0.5 - offset)) {
            if (!actionTriggered) {
                line.style.width = '100%';
                actionTriggered = true;
            }
        } else {
            line.style.width = '0%';
            actionTriggered = false;
        }
    }

    window.addEventListener('load', checkVisibility);
    window.addEventListener('scroll', checkVisibility);

    window.addEventListener('load', () => {
        setTimeout(() => {
            line.style.width = '100%';
        }, 2000);
    });
}