export default function Search(element) {
    
    const modalSearch = document.querySelector('.modal__search');
    const btnClose = document.querySelector('#close__modal');
    const btnsOpen = document.querySelectorAll('.open__search');

    // btnsOpen.forEach(btnOpen => {
    //     btnOpen.addEventListener('click', () => {
    //         const inputElement = modalSearch.querySelector('#simple-search');
    //         modalSearch.classList.add('show');
    //         inputElement.focus();
    //     })
    // });

    // btnClose.addEventListener('click', () => {
    //     modalSearch.classList.remove('show')
    // })

}