export default function Share(element, Notifications) {

    var popupCenter = function(url, title, width, height){
        var popupWidth = width || 640;
        var popupHeight = height || 320;
        var windowLeft = window.screenLeft || window.screenX;
        var windowTop = window.screenTop || window.screenY;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
        var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
        var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
        popup.focus();
        return true;
    };

    // SHARE BY TWITTER
    if (document.querySelector('.share__twitter') != null) {
      document.querySelector('.share__twitter').addEventListener('click', function(e){
          e.preventDefault();
  
          var url = this.getAttribute('data-url');
  
          var shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(url);
          popupCenter(shareUrl, "Partager sur Twitter");
      });
    }

    // SHARE BY FACEBOOK
    if (document.querySelector('.share__facebook') != null) {
  
      document.querySelector('.share__facebook').addEventListener('click', function(e){
          e.preventDefault();
          var url = this.getAttribute('data-url');
  
          var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
          popupCenter(shareUrl, "Partager sur facebook");
      });
      }

    // SHARE BY EMAIL
    if (document.querySelector('.share__email') != null) {
        document.querySelector('.share__email').addEventListener('click', function(e){
            e.preventDefault();
            
          const url = window.location.protocol + "//" + window.location.host;
          const subject = `Je voulais te faire partager cette page!`;
          const body= `Bonjour,\n\nVous trouverez un lien ci-dessous pour accéder a l'annonce :\n${url}\n\nPour toute question n'hésitez pas à nous contacter en écrivant à :\nadresse@mail.fr\n\nÀ très vite sur Benenota !\nhttps://www.benenota.fr/`
          ;
          const encodedBody = encodeURIComponent(body);
          
          document.location.href= "mailto:?subject="+subject+"&body=" + encodedBody;
      });
    }
  
    // COPY LINK
    const copytext = document.querySelector('.link-copy');
    if (copytext) {
          copytext.addEventListener('click', function (e) {
              e.preventDefault();
              copyToClipboard(copytext.getAttribute('href'));
          });
    }
    const copyToClipboard = async (text) => {
          try {
              // Vérifie si l'API Clipboard est disponible et la connexion est sécurisée
              if (navigator.clipboard && window.isSecureContext) {
                  // Copie le texte dans le presse-papiers
                  await navigator.clipboard.writeText(text);
                  Notifications.Toast.fire({
                      title: 'Lien copié',
                      icon: 'success',
                      timerProgressBar: false
                  });
              } else {
                  console.error('L\'API Clipboard n\'est pas disponible ou la connexion n\'est pas sécurisée.');
                  Notifications.Toast.fire({
                      title: 'Impossible de copier le lien',
                      icon: 'error',
                      timerProgressBar: false
                  });
              }
          } catch (err) {
              // Gère les erreurs éventuelles
              console.error('Erreur lors de la copie dans le presse-papiers : ', err);
              Notifications.Toast.fire({
                  title: 'Une erreur est survenue, veuillez réessayer plus tard',
                  icon: 'error'
              });
          }
    };

}