export default class InstaFeed {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({
            api: 'API-url',
        },options);
        this.init();
    }

    init() {
        const getPost = fetch(this.options.api)
        .then((response) => {
            return response.json();
        })
        .then((posts) => {
            for (const post of posts) {
                const item = document.createElement('div');
                item.classList.add('instafeed__item');
                this.selector.appendChild(item);

                const link = document.createElement('a');
                link.href = post.permalink;
                link.target = '_blank';
                item.appendChild(link);

                // check si c'est une image ou une vidéo
                const image = document.createElement('img');
                image.alt = post.caption;
                image.width = post.dimensions.width;
                image.height = post.dimensions.height;

                if (post.mediaType === 'VIDEO') {
                    image.src = post.thumbnailUrl;
                } else {
                    image.src = post.mediaUrl;
                }
                link.appendChild(image);
            }
        });
    }
}
